<template>
  <svg viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2831_6954)">
      <path
        d="M29.7347 20.8199H1.26532V1.18038H29.7349L29.7347 20.8199ZM29.7347 0H1.26532C0.930111 0.00114289 0.608984 0.125871 0.371956 0.346989C0.134928 0.568106 0.00122513 0.867678 0 1.18038L0 20.8199C0.00122513 21.1326 0.134928 21.4321 0.371956 21.6532C0.608984 21.8744 0.930111 21.9991 1.26532 22.0002H29.7349C30.0701 21.9991 30.3913 21.8744 30.6283 21.6532C30.8653 21.4321 30.999 21.1326 31.0003 20.8199V1.18038C30.999 0.867678 30.8653 0.568106 30.6283 0.346989C30.3913 0.125871 30.0699 0.00114289 29.7347 0Z"
        fill="currentColor"
      />
      <path
        d="M18.4719 8.1084C16.7987 8.1084 15.3035 8.91741 15.3035 10.4123C15.3035 12.1265 17.9553 12.2448 17.9553 13.1061C17.9553 13.4688 17.5099 13.7934 16.7491 13.7934C15.6693 13.7934 14.8625 13.3399 14.8625 13.3399L14.5171 14.8482C14.5171 14.8482 15.4468 15.2314 16.6809 15.2314C18.5102 15.2314 19.9498 14.3826 19.9498 12.8622C19.9498 11.0509 17.2869 10.936 17.2869 10.1366C17.2869 9.85246 17.6527 9.54134 18.4112 9.54134C19.2671 9.54134 19.9656 9.87126 19.9656 9.87126L20.3035 8.41446C20.3035 8.41446 19.5437 8.1084 18.4719 8.1084ZM3.16361 8.21829L3.12305 8.43808C3.12305 8.43808 3.82701 8.55833 4.46096 8.79788C5.27729 9.07285 5.33541 9.23287 5.47285 9.7298L6.97092 15.1174H8.97921L12.073 8.21805H10.0694L8.08124 12.909L7.27007 8.93259C7.19567 8.4776 6.81876 8.21781 6.35764 8.21781H3.16335L3.16361 8.21829ZM12.879 8.21829L11.3073 15.1177H13.2179L14.7842 8.21829H12.879ZM23.5355 8.21829C23.0749 8.21829 22.8308 8.44844 22.6515 8.85042L19.8522 15.1177H21.8558L22.2436 14.0732H24.6846L24.9202 15.1177H26.6882L25.146 8.21829H23.5355ZM23.7962 10.0824L24.3901 12.6714H22.799L23.7962 10.0824Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2831_6954">
        <rect width="31" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
